import React from 'react';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import MainLayout from 'layouts/mainLayout';
import ForgotPasswordPage from 'components/Pages/callcenter/password';

const LoginTemplate = () => (
  <MainLayout>
    <CircularProgressOverlay>
      <ForgotPasswordPage />
    </CircularProgressOverlay>
  </MainLayout>
);

export default LoginTemplate;
