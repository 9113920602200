import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { notNull, isNull } from 'utils/text';
import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { callcenterService } from 'services/callcenterService';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Pergo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/featured/?callcenter)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function ForgotPasswordPage() {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    center_name: '',
    email: '',
    password: '',
    verification_code: '',
    tmp_verify_code: '',
  });
  const [formErrors, setFormErrors] = useState(null);
  const [isError, setError] = useState(false);
  const [step, setStep] = useState(0);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  
  const handleChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value
    });
  }

  const checkValueValidate = (id, value) => {
    let valid = notNull(value) && value.length > 0;
    let error = null;

    if (!valid) {
      error = 'Please input value';
    } else if (id === 'email') {
      valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      error = valid ? null : 'Please input valid Email';
    } else if (id === 'password') {
      valid = value.length >= 6;
      error = valid ? null : 'Please input strong password';
    } else if (id === 'cpassword') {
      valid = formValues.password === formValues.cpassword;
      error = valid ? null : 'Please input correctly';
    }

    return error;
  };

  const checkStepValidate = (keys) => {
    let errors = {};
    keys.forEach(id => {
      const error = checkValueValidate(id, notNull(formValues) ? formValues[id] : null);
      errors[id] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleResendCode = async () => {
    showCircularProgress('Please wait...');
    const data = await callcenterService.resend_verify_code({ email: formValues.email });
    hideCircularProgress();
  }

  const handleSubmit = async () => {
    const keys = step === 0 ? ['email'] : step === 1 ? ['verification_code'] : ['password', 'cpassword'];
    if (!checkStepValidate(keys)) {
      return;
    }

    if (step === 0) {
      showCircularProgress('Please wait');
      const data = await callcenterService.resend_verify_code({ email: formValues.email });
      hideCircularProgress();
      if (data.data) {
        setStep(1);
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } else if (step === 1) {
      showCircularProgress('Verifying...');
      const data = await callcenterService.verify_email({ email: formValues.email, verify_code: formValues.verification_code });
      hideCircularProgress();
      if (data.data) {
        if (data.data.status === 'SUCCESS') {
          setStep(2);
          handleChange('tmp_verify_code', data.data.data.tmp_verify_code);
        } else {
          setFormErrors({
            verification_code: 'Wrong verify code.'
          });
        }
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } else {
      showCircularProgress('Please wait');
      const data = await callcenterService.update_password({ email: formValues.email, password: formValues.password, tmp_verify_code: formValues.tmp_verify_code });
      hideCircularProgress();
      if (data.data) {
        if (data.data.status === 'SUCCESS') {
          navigate('/callcenter/login');
        } else {
          setError('Something went wrong. Please try again later.');
        }
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Container item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PhoneTwoToneIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className="mb-3">
            Forgot Call Center Password
          </Typography>
          <div className={classes.form}>
            {isError && (
              <Typography component="h6" color="error">
                {isError}
              </Typography>
            )}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              value={formValues.email}
              onChange={e => handleChange('email', e.target.value)}
              error={notNull(formErrors?.email)}
              helperText={formErrors?.email}
            />
            {step >= 1 && (
              <>
                <p className="step-description">We sent a verification code to your email. Please check your email and enter the code we sent you below.</p>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0 15px' }}>
                  <div style={{ flex: 2 }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Verification Code"
                      value={formValues.verification_code}
                      onChange={e => handleChange('verification_code', e.target.value)}
                      error={notNull(formErrors?.verification_code)}
                      helperText={formErrors?.verification_code}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="mt-4 mb-3 py-3"
                      onClick={handleResendCode}
                    >
                      Resend Code
                    </Button>
                  </div>
                </div>
              </>
            )}
            {step >= 2 && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  value={formValues.password}
                  onChange={e => handleChange('password', e.target.value)}
                  error={notNull(formErrors?.password)}
                  helperText={formErrors?.password}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  value={formValues.cpassword}
                  onChange={e => handleChange('cpassword', e.target.value)}
                  error={notNull(formErrors?.cpassword)}
                  helperText={formErrors?.cpassword}
                />
              </>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-4 mb-3 py-3"
              onClick={handleSubmit}
            >
              Next
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/callcenter/login" variant="body2">
                  I already have account. Go to Signin
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
          <Link href="/login" variant="body2" style={{ marginTop: 30 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className="mt-4 mb-3 py-3"
            >
              <span className="btn-wrapper--label">Go to Portal</span>
            </Button>
          </Link>
        </div>
      </Container>
    </Grid>
  );
}

const Container = styled(Grid)`
  .step-description {
    font-size: 14px;
    color: #888;
    margin: 20px 0 0;

    &.error-color {
      color: #b00;
    }
  }
`;